import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const ContactForm = () => {
  return (
    <div className="max-w-lg mx-4 mt-10 lg:mx-auto">
    <form action="/thankyou" method="POST" name="contact" className="grid grid-cols-1 gap-y-6" netlify-honeypot="bot-field" data-netlify="true">
      <input type="hidden" name="form-name" value="contact"/>
      <p className="hidden">
      <label>Don’t fill this out if you’re human: <input name="bot-field" /></label>
      </p>
      <div>
        <label htmlFor="full-name" className="sr-only">
          Full name
        </label>
        <input
          type="text"
          name="full-name"
          id="full-name"
          autoComplete="name"
          className="block w-full px-4 py-3 placeholder-gray-500 border-2 border-gray-200 rounded-md shadow-sm focus:ring-relay-green-primary focus:border-relay-green-primary"
          placeholder="Full name"
        />
      </div>
      <div>
        <label htmlFor="email" className="sr-only">
          Email
        </label>
        <input
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          className="block w-full px-4 py-3 placeholder-gray-500 border-2 border-gray-200 rounded-md shadow-sm focus:ring-relay-green-primary focus:border-relay-green-primary"
          placeholder="Email"
        />
      </div>
      <div>
        <label htmlFor="phone" className="sr-only">
          Phone
        </label>
        <input
          type="text"
          name="phone"
          id="phone"
          autoComplete="tel"
          className="block w-full px-4 py-3 placeholder-gray-500 border-2 border-gray-200 rounded-md shadow-sm focus:ring-relay-green-primary focus:border-relay-green-primary"
          placeholder="Phone"
        />
      </div>
      <div>
        <label htmlFor="message" className="sr-only">
          Message
        </label>
        <textarea
          id="message"
          name="message"
          rows={4}
          className="block w-full px-4 py-3 placeholder-gray-500 border border-gray-300 rounded-md shadow-sm focus:ring-relay-green-primary focus:border-relay-green-primary"
          placeholder="Message"
          defaultValue={''}
        />
      </div>
      <div>
        <button
          type="submit"
          className="inline-flex justify-center px-6 py-3 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-relay-green-primary hover:bg-relay-green-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-relay-green-primary"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
  )
}

const Header = () => {
  return(
    <section className="mt-5 lg:mt-20">
      <div className="flex flex-col mx-4 lg:max-w-screen-md lg:mx-auto">
        <h1 className="mb-4 text-4xl font-extrabold lg:text-5xl text-relay-text">Contact us</h1>
        <p className="text-base font-semibold lg:text-2xl">Have questions, concerns or just need to clarify something?</p>
        <p className="text-base font-semibold lg:text-2xl">Drop us a line and we'd be glad to help.</p>
      </div>
    </section>
  )
}

const ContactUsPage = () => {
  return (
    <Layout>
      <Seo 
        title="Contact Us" 
        description="We love to hear from users, come say hello"
      />
      <Header/>
      <ContactForm/>
    </Layout>
  )
}

export default ContactUsPage
